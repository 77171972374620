import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'

const SubscribePage = () => {
  return (
    <Layout>
      <section style={{ textAlign: `center` }}>
        <h1 style={{ color: `rebeccapurple` }}> Thanks for Subscribing!</h1>
        <StaticImage
          src="../data/headers/laurieoncorgi.jpg"
          alt="Laurie on corgi"
          width={500}
        />
      </section>
    </Layout>
  )
}

export default SubscribePage
